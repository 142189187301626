.multiSelect {
    margin-bottom: 10px;
}

.multiSelectDownArrow {
    width: 14px;
    height: 14px;
    margin: 11px 7px 0 0;
}

.multiSelectDownArrow.open {
    transform: rotate(180deg);
}

.multiSelect input {
    height: unset;
}


.multiSelect .bp3-multi-select{
    min-height: 40px;
    line-height: 40px;
    padding: 0 0 0 .5rem;
    margin: 0 0 1rem;
    color: #111;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: none;
}

.multiSelect .bp3-tag-input input.bp3-input-ghost {
    width: 10px;
}

.multiSelect input:focus {
    box-shadow: none;
}

.multiSelect button.bp3-tag-remove {
    width: unset !important;
    border-radius: unset !important;
    height: unset !important;
    line-height: unset !important;
    margin: 0 !important;
    border-bottom: none !important;
}

.multiSelect button.bp3-tag-remove::after {
    width: unset;
    height: unset;
    line-height: unset;
    margin: 0;

}
